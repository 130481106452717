<template>
  <div class="base-agreement">
    <div class="agreement-wrapper flex items-center justify-between pl-30 pr-45">
      <p class="text-color-gray text-16">
        {{ $t('我们使用cookie来分析网站的使用情况，增强您的使用服务体验 。如需更多详细信息，请访问我们的') }}

        <a
          href="https://doc.weixin.qq.com/doc/w3_AMQANAbgAAgftQGWfBRT3uRO1ja4Q?scode=AGYAWgcrABEbm9LrLhAMQANAbgAAg"
          target="_blank"
          class="text-blue"
        >
          {{ $t('隐私政策') }}
        </a>
        {{ $t('页面。继续使用本网站，即表示您同意我们使用cookie。') }}
      </p>

      <el-button type="primary" class="btn w-130 text-16" @click="agree">{{ $t('同意') }}</el-button>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['agree'])
const agree = () => {
  emit('agree')
}
</script>

<style lang="scss" scoped>
.base-agreement {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;

  .agreement-wrapper {
    width: 1400px;
    height: 110px;
    margin: 0 auto;
    background: $bg-white-color;
    box-shadow:
      0px -3px 0px 0px $border-blue-color,
      0px 0px 6px 1px rgba($black-color, 0.25);
    border-radius: 4px;

    .btn {
      height: 46px;
      margin-left: 100px;
    }
  }
}
</style>
